import React, { useEffect } from "react";
import spinner from "../../assets/images/webp/spinner.webp";
import { Link, useLocation } from "react-router-dom";
const Sidebar = ({ isOpen, onClose }) => {
  const path = useLocation().pathname;

  // FOR WEHN THE SIDEBAR IS OPEN THEN THE PAGE WILL NOT BE SCROLL
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow_hidden");
    } else {
      document.body.classList.remove("overflow_hidden");
    }
  }, [isOpen]);
  return (
    <>
      <div
        // onClick={onClose}
        className={`fixed top-0 z-30 max-w-[400px] w-full border-l-[8px] border-yellow h-screen bg-black transition-all duration-300 ease-in-out sidebar_data p-6 md:ps-11 sm:pt-10 sm:pe-3  ${
          isOpen ? "right-[0]" : "right-[-400px] "
        }`}
      >
        <span className="flex justify-end items-center sm:pe-4 md:pe-8 ">
          <button id="ahh" aria-label="Name" onClick={onClose}>
            <h2 className="leading-normal text-md sm:text-lg lg:text-2xl ff_arco text-white ">
              X
            </h2>
          </button>
        </span>
        <div className="flex flex-col h-[75vh] overflow-y-auto pe-3">
          <Link
            onClick={onClose}
            to="/"
            className={`mb-4 text-White hover:bg-blue ff_arco font-normal text-xs xl:text-[24px] uppercase sidebar_para transition-all duration-300 ease-in-out rounded-md py-2 ps-4 ${
              path === "/" ? "text-White bg-blue pt-3 pb-2 ps-4" : ""
            }`}
          >
            HOME
          </Link>
          <Link
            onClick={onClose}
            to="/story"
            className={`mb-4  text-White ff_arco font-normal text-xs xl:text-[24px] uppercase sidebar_para transition-all duration-300 ease-in-out hover:bg-blue rounded-md py-2 ps-4 ${
              path === "/story" ? "text-White bg-blue pt-3 pb-2 ps-4" : ""
            }`}
          >
            STORY
          </Link>
          <Link
            to="/labpage"
            onClick={onClose}
            className={`mb-4 text-White ff_arco font-normal text-xs xl:text-[24px]  uppercase sidebar_para hover:bg-blue transition-all duration-300 ease-in-out rounded-md py-2 ps-4 ${
              path === "/labpage" ? "text-White bg-blue  py-2 ps-4 " : ""
            }`}
          >
            Laboratory
          </Link>
          <button
            id="kid"
            aria-label="Name"
            aria-disabled="true"
            className="disabled cursor-not-allowed pointer-events-none text-left mb-4 text-White ff_arco font-normal text-xs xl:text-[24px] uppercase sidebar_para   opacity-40 hover:bg-blue transition-all duration-300 ease-in-out rounded-md py-1 ps-4"
          >
            Stitched KIDS
          </button>
          <button
            id="merch"
            aria-label="Name"
            aria-disabled="true"
            className="disabled cursor-not-allowed pointer-events-none text-left mb-4 text-White ff_arco font-normal text-xs xl:text-[24px] uppercase sidebar_para   opacity-40  hover:bg-blue transition-all duration-300 ease-in-out rounded-md py-1 ps-4"
          >
            MERCH
          </button>
          <a
            href="https://magiceden.io/marketplace/stitched_stories"
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClose}
            className="mb-4 text-White ff_arco font-normal text-xs xl:text-[24px]  uppercase sidebar_para hover:bg-blue transition-all duration-300 ease-in-out rounded-md py-2 ps-4"
          >
            Collection
          </a>
          <button
            id="event"
            aria-label="Name"
            aria-disabled="true"
            className="disabled cursor-not-allowed pointer-events-none mb-4 text-left text-White ff_arco font-normal text-xs xl:text-[24px] uppercase sidebar_para  opacity-40 hover:bg-blue transition-all duration-300 ease-in-out rounded-md py-1 ps-4"
          >
            EVENTS
          </button>
          <button
            id="casino"
            aria-label="Casino"
            aria-disabled="true"
            className="disabled cursor-not-allowed pointer-events-none mb-4 text-left text-White ff_arco font-normal text-xs xl:text-[24px] uppercase sidebar_para  opacity-40 hover:bg-blue transition-all duration-300 ease-in-out rounded-md py-1 ps-4"
          >
            EC Casino
          </button>
          <a
            href="https://twitter.com/StitchedNFTs"
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClose}
            className=" mb-4 text-White ff_arco font-normal text-xs text-left xl:text-[24px] uppercase sidebar_para hover:bg-blue transition-all duration-300 ease-in-out rounded-md py-1 ps-4"
          >
            TWITTER
          </a>
          <a
            href="https://discord.com/invite/ke6rd3jE"
            onClick={onClose}
            target="_blank"
            rel="noopener noreferrer"
            className="mb-4 text-left text-White ff_arco font-normal text-xs xl:text-[24px] uppercase sidebar_para hover:bg-blue transition-all duration-300 ease-in-out rounded-md py-1 ps-4"
          >
            DISCORD
          </a>
        </div>
        <a href="/" className="flex justify-end items-center ">
          <img
            onClick={onClose}
            className=" w-20 h-20 sm:w-[80px] sm:h-[80px] cursor-pointer"
            src={spinner}
            alt="spinner"
          />
        </a>
      </div>
    </>
  );
};

export default Sidebar;
