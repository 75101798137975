import React, { useEffect } from "react";
import doNotEnterImg from "../../assets/images/webp/do_not_enter_img.webp";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";

const TheLab = () => {
  const [visible, setVisible] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });
  useEffect(() => {
    
    if (inView) {
      setVisible(true);
    }
  }, [inView]);
  return (
    <section className="bg-white py-6 md:py-10 lg:py-12 xl:pt-[45px] xl:pb-[19px] relative">
      <div
        className={`container custom_container ${inView ? "the_lab" : ""} ${visible?"":""}`}
        ref={ref}
      >
        <div className="flex flex-wrap items-center">
          <div className="w-full lg:w-2/5 ">
            <img
              className="w-[70%] h-[70%] lg:w-full mx-auto"
              src={doNotEnterImg}
              alt="book"
            />
          </div>
          <div className="w-full lg:w-3/5 ">
            <h4 className=" text-[45px] md:text-[85px]  xl:text-4xl md:leading-[66px] xl:leading-[186px]  ff_arco font-bold text-center sm:mt-6 text-[#231f20]">
              {/* THE LAB */}
              <span className="animated-letter letter_1">T</span>
              <span className="animated-letter letter_2">H</span>
              <span className="animated-letter letter_3">E&nbsp;</span>
              <span className="animated-letter letter_4">L</span>
              <span className="animated-letter letter_5">A</span>
              <span className="animated-letter letter_6">B</span>
            </h4>
            <p
              className="text-xs leading-5 md:text-base text-[#231f20] font-normal md:leading-9 mt-[10px] md:mt-[23px]"
              data-aos="fade-left"
            >
              Inside the lab, the mystical Eterra Stone drives its enchanting
              and occasionally unpredictable atmosphere. Every significant event
              in the town appears to be connected to it. Keep in mind that the
              lab will evolve as the story unfolds.
            </p>
            <div className="text-center mt-5 md:mt-[25px] xl:mt-[50px] xl:mb-28">
              <Link to="/labpage">
                <button className="inline-block text-xs md:text-sm xl:h-[67px] xl:text-lg font-bold ff_arco text-White py-2 px-[70px] bg-black rounded-full hover:bg-lightblue transition-all duration-300 ease-in-out">
                  ENTER
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TheLab;
