import React from "react";
import { useState } from "react";

const StoriesCards = ({ obj, index }) => {
  const [imageHover, setImageHover] = useState(false);

  // FOR OPEN PDF FILE WHEN CLICK
  const handleImageClick = () => {
    window.open(obj.pdf);
  };
  // FOR ZOOM IN AND CHANGE IMAGE FILTER WHEN CURSOR ON THE IMAGE
  const handleImageMouseEnter = () => {
    setImageHover(true);
  };

  // FOR ZOOM OUT AND CHANGE IMAGE FILTER AGAIN WHEN LEAVE CURSOR FROM THE IMAGE
  const handleImageMouseLeave = () => {
    setImageHover(false);
  };
  return (
    <>
      <div className="flex flex-col items-center md:flex-row  justify-between  pt-[30px]  md:pt-[50px]">
        <div className="w-[100%] md:w-[15%] group-hover:opacity-20">
          <h2
            className={`ff_arco font-bold hover_text text-[40px] md:text-[100px] xl:text-[175px] text-White  text-center md:text-start ${
              imageHover ? "opacity-100 " : "opacity-50 "
            } `}
          >
            {index + 1}
          </h2>
        </div>
        <div
          className="w-[100%] group md:w-[85%] hover_img cursor-pointer relative !overflow-hidden rounded-[30px]"
          // FOR ZOOM IN AND CHANGE IMAGE FILTER WHEN CURSOR ON THE IMAGE
          onMouseEnter={() => handleImageMouseEnter(index)}
          // FOR ZOOM OUT AND CHANGE IMAGE FILTER AGAIN WHEN LEAVE CURSOR FROM THE IMAGE
          onMouseLeave={() => handleImageMouseLeave(index)}
        >
          <div className="relative z-10 !overflow-hidden rounded-[30px]">
            <img
              // FOR OPEN PDF FILE WHEN CLICK
              onClick={() => handleImageClick(index)}
              className="w-full min-h-[150px] rounded-[30px]  md:h-full lg:min-h-[200px] xl:min-h-[250px] object-cover object-left md:object-center story_page_img1 duration-500"
              src={obj.img}
              alt="story_page_1"
            />
          </div>
          <h4 className="ff_arco font-bold text-[16px] md:text-[40px] text-White absolute bottom-[10%] left-[40px] opacity-0 hover_text">
            {obj.title}
          </h4>
        </div>
      </div>
    </>
  );
};

export default StoriesCards;
