import React, { useLayoutEffect } from "react";
import leftbears from "../../assets/images/webp/leftberas.webp";
import mainbear from "../../assets/images/webp/centerbear.webp";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const WeAllAreStiched = () => {
  // FOR THE ANIMATION OF 5 BEARS
  useLayoutEffect(() => {
    let reject = gsap.timeline({
      scrollTrigger: {
        trigger: ".integrated_section",
        start: "top 30%",
        end: "70% 20%",
        scrub: 1,
      },
    });
    reject
      .set(".main_bear", {
        scale: 1.2,
      })
      .fromTo(
        ".main_bear",
        {
          yPercent: 75,
          duration: 0.4,
        },
        {
          yPercent: 0,
          duration: 0.4,
        }
      )
      .fromTo(
        ".side_bears",
        {
          yPercent: 75,
          duration: 0.4,
        },
        {
          yPercent: 0,
          duration: 0.4,
        },
        "-=0.2"
      );
  }, []);
  return (
    <>
      <section className="overflow-hidden integrated_section">
        <div className="container custom_container">
          <p className="ff_arco text-md md:text-[28px] xl:text-[62px]  mt-1 xl:mt-2  text-[#231f20] mb-0  xl:leading-[110px] text-center">
            WE ARE ALL
          </p>
          <h2 className="ff_arco font-bold text-[45px] md:text-[85px] xl:text-4xl text-[#231f20] mb-0 leading-10 md:leading-[60px] xl:leading-[110px] 2xl:leading-[76px] para-leading text-center">
            STITCHED
          </h2>
          <p
            className="font-normal text-[14px] sm:text-xs 2xl:text-base  text-[#231f20] leading-5 xl:leading-6 2xl:leading-9 mb-0 mt-5"
            data-aos="fade-up"
          >
            Welcome to Stitched Stories, a place that celebrates connection,
            unity, and togetherness. We excel at offering a wide range of
            licensing opportunities, including toys, comics, games, and
            educational content.
          </p>
          <p
            className="font-normal text-[14px] sm:text-xs 2xl:text-base  text-[#231f20]  leading-5 xl:leading-6 2xl:leading-9 sm:pb-4 my-5 md:mt-10"
            data-aos="fade-up"
          >
            Our dedicated team is committed to creating value and providing
            unforgettable experiences for our NFT holders. Join us on this
            exciting journey as we continue to expand and evolve in the world of
            digital collectibles.
          </p>
          <div className="flex items-center justify-center relative xl:mx-40 2xl:mx-60 mt-14 lg:mt-7">
            <img
              className="w-full h-full side_bears"
              src={leftbears}
              alt="story"
            />
          </div>
          <div className="flex items-center justify-center -bottom-3 relative xl:-mt-96 ">
            <img
              className="-mt-[129px] main_bear"
              src={mainbear}
              alt="mainbear"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default WeAllAreStiched;
