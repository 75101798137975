import React, { useEffect, useState } from "react";
import handImage from "../../assets/images/webp/hand_image.webp";
import { Link } from "react-router-dom";
import { useInView } from "react-intersection-observer";
const Story = () => {
  const [visible, setVisible] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: false,
  });
  useEffect(() => {
    if (inView) {
      setVisible(true);
    }
  }, [inView]);
  return (
    <>
      <section
        className="bg-storybackground bg-no-repeat bg-cover py-6 md:py-10 lg:py-12 xl:pt-[58px] xl:pb-[118px] relative"
        ref={ref}
      >
        <div className="hidden xl:flex">
          <img
            className="absolute h-[120%] w-[437px] lg:w-auto bottom-[-10px] right-[-15px]"
            src={handImage}
            alt="hand"
          />
        </div>
        <div
          className={`container custom_container ${
            visible || inView ? "the_story_section" : ""
          }`}
        >
          <div className="md:mt-10">
            <h3 className="text-[45px] md:text-[85px]  xl:text-4xl  md:leading-[66px] xl:leading-[186px] ff_arco  text-White font-bold">
              <span className="animated-letter letter_1">S</span>
              <span className="animated-letter letter_2">T</span>
              <span className="animated-letter letter_3">O</span>
              <span className="animated-letter letter_4">R</span>
              <span className="relative animated-letter letter_4">Y</span>
            </h3>
            <p
              className=" text-[14px] sm:text-xs leading-5 2xl:text-base 2xl:leading-9 text-white md:mt-6 2xl:mt-[40px] xl:w-[70%]"
              data-aos="fade-right"
            >
              The comic is more than just an origin story; it serves as a
              guiding blueprint for our brand and lays the foundation for our
              animated series, which represents our vision for the future.
              Anyways, you'll get the idea...
            </p>
            <p
              className=" text-[14px] sm:text-xs leading-5 2xl:text-base 2xl:leading-9 text-white md:mt-6 xl:w-[70%]"
              data-aos="fade-right"
            >
              In a faraway place high above the clouds, there is a magical city
              called Eterra. It's a special place where amazing people live. Let
              us share a story about a boy named Jimmy, whose father is deeply
              absorbed in peculiar experiments within his lab. Oh, and did we
              mention that he has a gigantic laser too? What could possibly go
              wrong?
            </p>
            <div className="sm:ms-20 xl:ms-32 mt-[25px] xl:mt-[68px]">
              <Link to="/story">
                <button
                  id="ag"
                  aria-label="Name"
                  className="text-[#231f20] px-[46px] xl:h-[67px] pt-[9px] pb-[8px] inline-block bg-white ff_arco text-xs md:text-sm xl:text-lg font-bold rounded-full hover:bg-lightblue hover:text-White transition-all ease-in-out duration-300"
                >
                  READ ME
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Story;
