import React from "react";
import Header from "../common/Header";
import heroVideo from "../../assets/video/Herosectionanimationwhitebg.mp4";

const HomePageHero = ({ setIsOpen, isOpen, MuteHandler, isPlaying }) => {
  return (
    <div className="h-[300px] sm:h-[85vh] md:h-[670px] lg:h-[755px] xl:h-[841px] 2xl:h-[1198px] bg-bottom md:bg-left lg:bg-top 2xl:bg-left relative mb-12 xl:mb-16">
      <video
        className="w-full h-[110%] object-cover object-left absolute top-0 left-0 z-[-1]"
        src={heroVideo}
        kind="captions"
        muted
        autoPlay
        loop
        playsInline
      ></video>
      <div className="container">
        <Header
          isPlaying={isPlaying}
          MuteHandler={MuteHandler}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
      </div>
    </div>
  );
};

export default HomePageHero;
