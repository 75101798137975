import React from "react";
import { Howl } from "howler";
import errorSound from "../../assets/audio/wronganswer-37702.mp3";
import { PopUpCrossIcon, Redicon } from "../common/Icons";
import { useEffect } from "react";
import Header from "./Header";
const LabPage = ({ isOpen, setIsOpen, MuteHandler, isPlaying }) => {
  const [showError, setShowError] = React.useState(false);
  const [showSoundPopup, setShowSoundPopup] = React.useState(false);

  // FOR THE SOUND AND THE POPUP DELAY
  useEffect(() => {
    const sound = new Howl({ src: [errorSound] });
    if (window.location.pathname === "/labpage") {
      if (showSoundPopup) {
        sound.pause();
      } else {
        setTimeout(() => {
          setShowError(true);
          sound.play();
          setShowSoundPopup(true);
        }, 1700);
      }
      if (showError) {
        document.body.classList.add("overflow_hidden");
      } else {
        document.body.classList.remove("overflow_hidden");
      }
    }
  });

  // FOR CLOSING THE WARNING POPUP
  const ClickHandler = () => {
    setShowError(false);
    setShowSoundPopup(true);
  };
  return (
    <>
      <section className="bg-labpagebg bg-cover bg-no-repeat bg-right min-h-screen relative flex flex-col">
        <div className="container z-20 relative">
          <Header
            isPlaying={isPlaying}
            MuteHandler={MuteHandler}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
        </div>
        <div className="grow h-full flex items-center relative">
          <a
            className="absolute w-[25vw] sm:w-[10vw] h-[25vw] sm:h-[10vw] right-[13%] sm:right-[6%] md:right-[3%] top-[49%] sm:top-[44%] rounded-full"
            href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
              "Ooops! I pressed the RED button! I wonder what will happen now? 😳  #stitchlist"
            )}&url=${encodeURIComponent("\nhttps://stitched-stories.com/")}`}
            target="_blank"
            rel="noopener noreferrer"
          ></a>
        </div>
        {showError && (
          <div className="error-popup px-3 sm:px-0">
            <div className="bg-white w-[296px] sm:w-fit mt-8 mx-auto absolute top-[10%] sm:top-[50%] left-[50%] translate-x-[-50%] sm:translate-y-[-50%]">
              <div className="flex justify-between p-3">
                <p className="text-[14px] sm:text-xs text-[#a2a3a3]">
                  Message from webpage
                </p>
                <span className="cursor-pointer" onClick={() => ClickHandler()}>
                  <PopUpCrossIcon />
                </span>
              </div>
              <div className="flex py-4 sm:py-8 px-3 sm:pl-6 sm:pe-10 items-center gap-3">
                <span
                  onClick={() => ClickHandler()}
                  className=" bg-[#CF2929] sm:p-[10px] border-[#A1A4A4] p-1  rounded-[115px] border-[2px] cursor-pointer"
                >
                  <Redicon />
                </span>
                <p className="text-[13px] lg:text-xs font-semibold max-w-xs">
                  Whatever you do in the lab, don't push the red button.
                </p>
              </div>
              <div className="bg-[#f0f0f0] px-6 py-4">
                <button
                  id="al"
                  aria-label="Name"
                  className="ml-auto flex px-10 py-2 border-2 border-gray-400 text-slate-950"
                  onClick={() => ClickHandler()}
                >
                  ok
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default LabPage;
