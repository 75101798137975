import { useState, useEffect } from "react";
import "./App.css";
import "./index.css";
import StichedRoute from "./components/common/StichedRoute";
import Sidebar from "./components/common/Sidebar";
import AOS from "aos";
import "aos/dist/aos.css";
import Backtotop from "./components/common/BackToTop";
import animationData from "./assets/lottie/preloader.json";
import backgroundAudio from "./assets/audio/Cottonforloopmp3.mp3";
import Lottie from "react-lottie";
import { useRef } from "react";

function App() {
  const [isPlaying, setIsPlaying] = useState(true);

  const audioRef = useRef(null);
  // FOR SOUND PLAY AND MUTE
  const MuteHandler = () => {
    setShowPage(true);
    const audioElement = audioRef.current;
    if (isPlaying) {
      audioElement.play();
    } else {
      audioElement.pause();
    }
    setIsPlaying(!isPlaying);
  };
// FOR THE LOTTIE ANIMATION IN PRELOADER
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [preloaderActive, setpreloaderActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showPage, setShowPage] = useState(false);
  console.log(preloaderActive);
  function toggleSidebar() {
    setIsOpen(!isOpen);
  }
  // TIMING FOR THE PRELOADER
  useEffect(() => {
    setTimeout(() => {
      setpreloaderActive(true);
    }, 3000);
    if (showPage) {
      document.body.classList.remove("overflow_hidden1");
    } else {
      document.body.classList.add("overflow_hidden1");
    }
  });
  const overLayHidden = () => {
    setIsOpen(!isOpen);
  };
  AOS.init({
    once: true, // whether animation should happen only once - while scrolling down
    duration: 800,
  });
  return (
    <>
      <audio ref={audioRef} src={backgroundAudio} loop autoPlay />
      {isPlaying ? (
        <audio src={backgroundAudio} loop autoPlay />
      ) : (
        <audio src={backgroundAudio} loop autoPlay />
      )}

      <div className="relative">
        <Backtotop />
        {showPage ? (
          ""
        ) : (
          <>
            <div className="bg-loadingbackground fixed h-screen right-0 w-full z-[100] bg-no-repeat bg-cover bg-right flex items-center justify-center">
              {preloaderActive ? (
                <>
                  <button
                    onClick={MuteHandler}
                    className="m-auto text-[#231f20] px-[46px] xl:h-[67px] pt-[9px] pb-[8px] inline-block bg-white ff_arco text-xs md:text-sm xl:text-lg font-bold rounded-full hover:bg-blue hover:text-White transition-all ease-in-out duration-300"
                  >
                    Eterra City
                  </button>
                </>
              ) : (
                <div className="h-full flex flex-col items-center justify-center">
                  <div className="mx-auto">
                    <div className="max-w-[200px] sm:max-w-[300px] mr-4 sm:mr-6">
                      <Lottie options={defaultOptions} />
                    </div>
                  </div>
                  <div className="mx-auto loader h-5 w-40 rounded-10 bg-transparent border-4 border-white mt-[-20px] sm:mt-[-40px]"></div>
                </div>
              )}
            </div>
          </>
        )}
        <StichedRoute
          isPlaying={isPlaying}
          MuteHandler={MuteHandler}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          onClose={toggleSidebar}
          setpreloaderActive={setpreloaderActive}
          preloaderActive={preloaderActive}
        />
        <Sidebar
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          onClose={toggleSidebar}
        />
        <div
          onClick={overLayHidden}
          className={` fixed inset-0 min-h-full z-20 backdrop-blur-[6px] cursor-pointer ${
            isOpen ? "w-[100%] " : "hidden"
          }`}
        ></div>
      </div>
    </>
  );
}

export default App;
