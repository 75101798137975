import React from "react";
import { useLocation } from "react-router-dom";
import { Muted, UnMuted } from "./Icons";

const Header = ({ setIsOpen, MuteHandler, isPlaying }) => {
  const path = useLocation().pathname;

  return (
    <section id="nav_bar">
      <div className="container custom_container px-0">
        <div className="pt-3 sm:pt-10 flex items-center justify-end gap-4 md:gap-5">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block text-xs sm:text-[24px] font-bold ff_arco text-white py-1 sm:py-2 px-[28px] sm:px-10 bg-black rounded-full hover:bg-lightblue transition-all duration-300 ease-in-out border-black border-2 hover:border-white"
            href="https://magiceden.io/marketplace/stitched_stories"
          >
            Buy
          </a>
          <span onClick={() => MuteHandler()}>
            {isPlaying ? <Muted /> : <UnMuted />}
          </span>
          {path === "/labpage" ? "" : null}
          <div
            className="cursor-pointer bg-lightblue opacity-80 shadow-lg p-2 sm:px-3 rounded-2xl"
            onClick={() => setIsOpen(true)}
          >
            <div className="bg-white w-8 sm:w-10 h-2 sm:h-3 rounded opacity-100 "></div>
            <div className="bg-white w-8 sm:w-10 h-2 sm:h-3 rounded mt-[2px]  opacity-100"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
