import React, { useRef, useEffect } from "react";
import Header from "../common/Header";
import story_page_cloud1 from "../../assets/images/webp/story_page_cloud1.webp";
import story_page_cloud2 from "../../assets/images/webp/story_page_cloud2.webp";
import story_page_logo from "../../assets/images/png/story_page_logo.png";
import { storiesCardsData } from "../common/Helper";
import StoriesCards from "../common/StoriesCards";

const Stories = ({ setIsOpen, isOpen, MuteHandler, isPlaying }) => {
  const tagContainerRef = useRef(null);

  useEffect(() => {
    if (tagContainerRef.current) {
      tagContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <section
        className="bg-storypagebg bg-no-repeat bg-cover min-h-screen xl:pb-[70px] 2xl:pb-[120px]"
        ref={tagContainerRef}
      >
        <div className="container custom_container">
          <div className="relative z-10">
            <Header
              isPlaying={isPlaying}
              MuteHandler={MuteHandler}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
            />
            <div>
              <img
                className="absolute top-[55px] left-0 md:left-[50px] w-[50%] md:max-w-[300px] xl:max-w-[400px] max-w-[550px] z-[-1] hidden md:block"
                src={story_page_cloud1}
                alt="story_page_cloud1"
              />
            </div>
            <img
              className="absolute top-[30px] xl:top-[55px] right-0 md:left-[45%] xl:left-[62%] w-[50%] max-w-[200px] z-[-1] hidden md:block"
              src={story_page_cloud2}
              alt="story_page_cloud2"
            />
            <div className="container custom_container lg:pr-[40px] pt-10 xl:pt-0 pb-12 ">
              <img
                className="mx-auto  w-[100%] max-w-[550px] lg:max-w-[600px] lg:mt-[-50px]"
                src={story_page_logo}
                alt="story_page_logo"
              />
              {storiesCardsData &&
                storiesCardsData.map((obj, index) => (
                  <StoriesCards obj={obj} key={index} index={index} />
                ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Stories;
