import React from "react";
import { Route, Routes } from "react-router-dom";
import Homepage from "../../view/Homepage";
import Stories from "../stichedStories/Stories";
import LabPage from "./LabPage";

const StichedRoute = ({ setIsOpen, isOpen,preloaderActive ,MuteHandler,isPlaying}) => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Homepage isPlaying={isPlaying} MuteHandler={MuteHandler} preloaderActive={preloaderActive} setIsOpen={setIsOpen} isOpen={isOpen} />}
        />
        <Route
          path="/story"
          element={<Stories isPlaying={isPlaying} setIsOpen={setIsOpen} isOpen={isOpen}  MuteHandler={MuteHandler}/>}
        />
        <Route
          path="/labpage"
          element={<LabPage isPlaying={isPlaying} setIsOpen={setIsOpen} isOpen={isOpen} MuteHandler={MuteHandler} />}
        />
      </Routes>
    </>
  );
};

export default StichedRoute;
