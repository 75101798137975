import React from "react";
import HomePageHero from "../components/homepage/HomePageHero";
import WeAllAreStiched from "../components/homepage/WeAllAreStiched";
import Story from "../components/homepage/Story";
import TheLab from "../components/homepage/TheLab";
import Footer from "../components/common/Footer";

const Homepage = ({ setIsOpen, isOpen, MuteHandler, isPlaying }) => {
  return (
    <>
      <div className="main-content">
        <HomePageHero
          isPlaying={isPlaying}
          MuteHandler={MuteHandler}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
        />
        <WeAllAreStiched />
        <Story />
        <TheLab />
        <Footer />
      </div>
    </>
  );
};

export default Homepage;
