import React from "react";
import { DiscordIcon, TwitterIcon } from "../common/Icons";
import ExamplePdf from "../../assets/pdf/FINAL_2.pdf";
import logo from "../../assets/images/png/footer_logo.png";

const Footer = () => {
  return (
    <footer className="bg-footerbackground bg-no-repeat bg-cover py-6 md:py-10 lg:py-12 xl:mt-[30px] xl:pb-[52px]">
      <div className="container">
        <div className="flex justify-center">
          <button id="al" aria-label="Name">
            <span className="w-[70px] lg:w-[133px]">
              <img className="max-w-[100px]" src={logo} alt="logo" />
            </span>
          </button>
        </div>
        <div className="text-center">
          <div className="flex justify-center gap-2 sm:gap-5">
            <span
              id="alk"
              aria-label="Name"
              className="text-xs sm:text-base font-normal text-yellow mt-[26px] leading-[36px] mb-6"
            >
              Brand.
            </span>
            <span
              id="experience"
              aria-label="Name"
              className="text-xs sm:text-base font-normal text-yellow mt-[26px] leading-[36px] mb-6"
            >
              Experience.
            </span>
            <span
              id="ment"
              aria-label="Name"
              className="text-xs sm:text-base font-normal text-yellow mt-[26px] leading-[36px] mb-6"
            >
              Movement.
            </span>
          </div>
          <p className="text-lg md:text-[66px]  xl:text-[72px] 2xl:text-[96px] font-bold  ff_arco text-White mb-0  ff_segoe">
            IT STARTS
          </p>
          <p className="text-lg md:text-[66px] xl:text-[72px] 2xl:text-[96px] font-bold   ff_arco text-White ff_segoe mt-[-20px] md:mt-[-42px] 2xl:mt-[-60px]">
            WITH YOU!
          </p>
          <div className="flex justify-center gap-[33px] mt-[42px]">
            <a
              className="hover:translate-y-[-7px] transition-all duration-300 ease-in-out"
              href="https://twitter.com/StitchedNFTs"
              aria-label="Read more about Seminole tax hike"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
            <a
              className="hover:translate-y-[-7px] transition-all duration-300 ease-in-out"
              href="https://discord.com/invite/ke6rd3jE"
              aria-label="Read more tax hike"
              target="_blank"
              rel="noreferrer"
            >
              <DiscordIcon />
            </a>
          </div>
          <div className="flex justify-center items-center gap-6 sm:gap-[38px] mt-[45px]">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={ExamplePdf}
              className="text-small sm:text-sm xl:text-base font-normal text-White ff_arco transition-all duration-300 ease-in-out hover:text-lightblue cursor-pointer
             whitespace-nowrap"
            >
              TERMS & CONDITIONS
            </a>
            <div className="hidden sm:flex w-[1px] h-[50px] bg-white border-[4px]"></div>
            <a
              href="mailto:office@stitched-stories.com"
              id="mention"
              aria-label="Name"
              className="text-small sm:text-sm xl:text-base font-normal ff_arco transition-all duration-300 ease-in-out hover:text-lightblue  cursor-pointer
             text-White whitespace-nowrap"
            >
              CONTACT US
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
