import story_page_img1 from "../../assets/images/png/01.png";
import story_page_img2 from "../../assets/images/png/02.png";
import story_page_img3 from "../../assets/images/png/03.png";
import story_page_img4 from "../../assets/images/png/04.png";
import story_page_img5 from "../../assets/images/png/5.png";
import pdf1 from "../../assets/pdf/PART1.pdf";
import pdf2 from "../../assets/pdf/PART2.pdf";
import pdf3 from "../../assets/pdf/PART3.pdf";
import pdf4 from "../../assets/pdf/PART4.pdf";
import pdf5 from "../../assets/pdf/PART5.pdf";
export const storiesCardsData = [
  {
    title: "THE PAWNSHOP",
    img: story_page_img1,
    pdf: pdf1,
  },
  {
    title: "THE PAWNSHOP",
    img: story_page_img2,
    pdf: pdf2,
  },
  {
    title: "THE PAWNSHOP",
    img: story_page_img3,
    pdf: pdf3,
  },
  {
    title: "THE PAWNSHOP",
    img: story_page_img4,
    pdf: pdf4,
  },
  {
    title: "THE PAWNSHOP",
    img: story_page_img5,
    pdf: pdf5,
  },
];
