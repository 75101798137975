export const UnMuted = () => {
  return (
    <svg
      className="max-w-[40px] sm:max-w-[50px] md:max-w-[60px] h-auto cursor-pointer"
      width="204"
      height="204"
      viewBox="0 0 204 204"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M155.789 102C155.789 90.894 150.459 81.0408 142.228 74.8512C138.477 72.0301 133.409 74.1545 131.606 78.4878V78.4878C129.801 82.8245 132.127 87.738 135.061 91.4064C137.386 94.3142 138.776 98.0098 138.776 102.04C138.776 106.07 137.371 109.775 135.021 112.693C132.087 116.338 129.79 121.229 131.588 125.549V125.549C133.398 129.899 138.494 132.017 142.249 129.171C150.468 122.942 155.789 113.096 155.789 102ZM155.566 43.0008C151.491 40.6661 146.476 42.762 144.671 47.0983V47.0983C142.867 51.4323 144.969 56.3525 148.942 58.853C163.284 67.879 172.802 83.8265 172.802 102C172.802 120.207 163.284 136.13 148.943 145.149C144.969 147.648 142.867 152.568 144.671 156.902V156.902C146.476 161.238 151.491 163.334 155.566 160.999C176.017 149.284 189.776 127.262 189.776 102C189.776 76.7376 176.017 54.7161 155.566 43.0008ZM29.8023 59.4867C24.2794 59.4867 19.8022 63.9639 19.8022 69.4867V134.473C19.8022 139.996 24.2794 144.473 29.8022 144.473H49.6449C52.2982 144.473 54.8427 145.528 56.7183 147.405L96.2022 186.915C102.501 193.218 113.276 188.757 113.276 179.846V24.1421C113.276 15.2331 102.504 10.7714 96.2046 17.0711L56.7179 56.5578C54.8425 58.4332 52.299 59.4867 49.6468 59.4867H29.8023Z"
        fill="white"
      />
    </svg>
  );
};
export const Muted = () => {
  return (
    <svg
      className="max-w-[40px] sm:max-w-[50px] md:max-w-[60px] h-auto cursor-pointer"
      width="204"
      height="204"
      viewBox="0 0 204 204"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M122.337 30.0009C122.338 28.9394 122.055 27.8968 121.519 26.9796C120.983 26.0623 120.213 25.3033 119.286 24.7799C118.36 24.2565 117.31 23.9876 116.246 24.0004C115.181 24.0133 114.138 24.3076 113.225 24.8532L54.47 59.975H32.0562C22.0771 59.975 14 68.0145 14 77.9739V125.995C14 135.942 22.0651 143.994 32.0562 143.994H54.47L113.213 179.14C114.127 179.688 115.17 179.985 116.237 179.999C117.303 180.014 118.355 179.746 119.283 179.222C120.211 178.698 120.983 177.938 121.52 177.019C122.057 176.1 122.339 175.055 122.337 173.992V30.0009ZM172.979 101.948L190 118.903L181.49 127.387L164.469 110.432L147.448 127.387L138.937 118.903L155.958 101.948L138.937 84.9935L147.448 76.51L164.469 93.4649L181.502 76.51L190 84.9935L172.979 101.948Z"
        fill="white"
      />
    </svg>
  );
};
export const CrossIcon = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.1741 0.394527L15.0758 14.095L24.0154 27.478H18.9637L13.6475 18.697C13.3125 18.1504 12.9158 17.4628 12.4574 16.634H12.3516C12.2634 16.7927 11.849 17.4804 11.1085 18.697L5.68648 27.478H0.687667L9.91827 14.2008L1.0844 0.394527H6.13611L11.373 9.65158C11.7609 10.3393 12.14 11.0445 12.5102 11.7675H12.616L19.3869 0.394527H24.1741Z"
        fill="white"
      />
    </svg>
  );
};

export const TwitterIcon = () => {
  return (
    <svg
      width="44"
      height="36"
      viewBox="0 0 44 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.7916 4.49992C42.1875 5.22909 40.4583 5.70825 38.6666 5.93742C40.5 4.83325 41.9166 3.08325 42.5833 0.979085C40.8541 2.02075 38.9375 2.74992 36.9166 3.16659C35.2708 1.37492 32.9583 0.333252 30.3333 0.333252C25.4375 0.333252 21.4375 4.33325 21.4375 9.27075C21.4375 9.97909 21.5208 10.6666 21.6666 11.3124C14.25 10.9374 7.64581 7.37492 3.24998 1.97909C2.47915 3.29159 2.04165 4.83325 2.04165 6.45825C2.04165 9.56242 3.60415 12.3124 6.02081 13.8749C4.54165 13.8749 3.16665 13.4583 1.95831 12.8333V12.8958C1.95831 17.2291 5.04165 20.8541 9.12498 21.6666C7.81399 22.0254 6.43768 22.0753 5.10415 21.8124C5.66999 23.5884 6.77818 25.1424 8.27293 26.256C9.76768 27.3696 11.5738 27.9868 13.4375 28.0208C10.2784 30.5217 6.36247 31.8735 2.33331 31.8541C1.62498 31.8541 0.916646 31.8124 0.208313 31.7291C4.16665 34.2708 8.87498 35.7499 13.9166 35.7499C30.3333 35.7499 39.3541 22.1249 39.3541 10.3124C39.3541 9.91659 39.3541 9.54158 39.3333 9.14575C41.0833 7.89575 42.5833 6.31242 43.7916 4.49992Z"
        fill="white"
      />
    </svg>
  );
};
export const ErrorIcon = () => {
  return (
    <svg
      className="inline-block me-4"
      width="49"
      height="49"
      viewBox="0 0 49 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.5002 4.08337C13.2302 4.08337 4.0835 13.23 4.0835 24.5C4.0835 35.77 13.2302 44.9167 24.5002 44.9167C35.7702 44.9167 44.9168 35.77 44.9168 24.5C44.9168 13.23 35.7702 4.08337 24.5002 4.08337ZM26.5418 34.7084H22.4585V30.625H26.5418V34.7084ZM26.5418 26.5417H22.4585V14.2917H26.5418V26.5417Z"
        fill="#FF0000"
      />
    </svg>
  );
};

export const DiscordIcon = () => {
  return (
    <svg
      width="44"
      height="34"
      viewBox="0 0 44 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.1459 3.10418C34.375 1.81251 31.375 0.875014 28.25 0.333348C28.2226 0.332472 28.1954 0.337613 28.1702 0.348412C28.145 0.35921 28.1224 0.375403 28.1042 0.395848C27.7292 1.08335 27.2917 1.97918 27 2.66668C23.6854 2.16668 20.3146 2.16668 17 2.66668C16.7084 1.95835 16.2709 1.08335 15.875 0.395848C15.8542 0.354182 15.7917 0.333348 15.7292 0.333348C12.6042 0.875014 9.62502 1.81251 6.83335 3.10418C6.81252 3.10418 6.79169 3.12501 6.77085 3.14585C1.10419 11.625 -0.458313 19.875 0.31252 28.0417C0.31252 28.0833 0.333354 28.125 0.37502 28.1458C4.12502 30.8958 7.72919 32.5625 11.2917 33.6667C11.3542 33.6875 11.4167 33.6667 11.4375 33.625C12.2709 32.4792 13.0209 31.2708 13.6667 30C13.7084 29.9167 13.6667 29.8333 13.5834 29.8125C12.3959 29.3542 11.2709 28.8125 10.1667 28.1875C10.0834 28.1458 10.0834 28.0208 10.1459 27.9583C10.375 27.7917 10.6042 27.6042 10.8334 27.4375C10.875 27.3958 10.9375 27.3958 10.9792 27.4167C18.1459 30.6875 25.875 30.6875 32.9584 27.4167C33 27.3958 33.0625 27.3958 33.1042 27.4375C33.3334 27.625 33.5625 27.7917 33.7917 27.9792C33.875 28.0417 33.875 28.1667 33.7709 28.2083C32.6875 28.8542 31.5417 29.375 30.3542 29.8333C30.2709 29.8542 30.25 29.9583 30.2709 30.0208C30.9375 31.2917 31.6875 32.5 32.5 33.6458C32.5625 33.6667 32.625 33.6875 32.6875 33.6667C36.2709 32.5625 39.875 30.8958 43.625 28.1458C43.6667 28.125 43.6875 28.0833 43.6875 28.0417C44.6042 18.6042 42.1667 10.4167 37.2292 3.14585C37.2084 3.12501 37.1875 3.10418 37.1459 3.10418ZM14.75 23.0625C12.6042 23.0625 10.8125 21.0833 10.8125 18.6458C10.8125 16.2083 12.5625 14.2292 14.75 14.2292C16.9584 14.2292 18.7084 16.2292 18.6875 18.6458C18.6875 21.0833 16.9375 23.0625 14.75 23.0625ZM29.2709 23.0625C27.125 23.0625 25.3334 21.0833 25.3334 18.6458C25.3334 16.2083 27.0834 14.2292 29.2709 14.2292C31.4792 14.2292 33.2292 16.2292 33.2084 18.6458C33.2084 21.0833 31.4792 23.0625 29.2709 23.0625Z"
        fill="white"
      />
    </svg>
  );
};
export const PopUpCrossIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.71983 5.72006C5.86045 5.57961 6.05108 5.50072 6.24983 5.50072C6.44858 5.50072 6.6392 5.57961 6.77983 5.72006L11.9998 10.9401L17.2198 5.72006C17.3115 5.62133 17.4281 5.54916 17.5574 5.51115C17.6866 5.47313 17.8237 5.47068 17.9542 5.50406C18.0848 5.53743 18.2039 5.60539 18.299 5.70078C18.3941 5.79617 18.4618 5.91545 18.4948 6.04606C18.5282 6.17643 18.5259 6.31341 18.4881 6.44257C18.4502 6.57173 18.3783 6.68832 18.2798 6.78006L13.0598 12.0001L18.2798 17.2201C18.3786 17.3117 18.4507 17.4283 18.4887 17.5576C18.5268 17.6868 18.5292 17.8239 18.4958 17.9545C18.4625 18.085 18.3945 18.2041 18.2991 18.2992C18.2037 18.3944 18.0844 18.462 17.9538 18.4951C17.8235 18.5285 17.6865 18.5261 17.5573 18.4883C17.4282 18.4505 17.3116 18.3785 17.2198 18.2801L11.9998 13.0601L6.77983 18.2801C6.63757 18.4125 6.44951 18.4846 6.2552 18.4812C6.06088 18.4779 5.87543 18.3993 5.73783 18.2621C5.60059 18.1245 5.52203 17.939 5.51867 17.7447C5.51532 17.5504 5.58742 17.3623 5.71983 17.2201L10.9398 12.0001L5.71983 6.78006C5.57938 6.63943 5.50049 6.44881 5.50049 6.25006C5.50049 6.05131 5.57938 5.86068 5.71983 5.72006Z"
        fill="#A2A3A3"
      />
    </svg>
  );
};

export const BackToTopArrow = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#02A9F7" />
      <path
        d="M23.7943 15.5916C23.8705 15.5152 23.961 15.4546 24.0607 15.4132C24.1603 15.3719 24.2672 15.3506 24.3751 15.3506C24.483 15.3506 24.5898 15.3719 24.6895 15.4132C24.7891 15.4546 24.8796 15.5152 24.9558 15.5916L34.7996 25.4353C34.9536 25.5894 35.0402 25.7983 35.0402 26.0161C35.0402 26.234 34.9536 26.4429 34.7996 26.5969C34.6456 26.7509 34.4366 26.8375 34.2188 26.8375C34.001 26.8375 33.7921 26.7509 33.638 26.5969L24.3751 17.3323L15.1121 26.5969C14.9581 26.7509 14.7491 26.8375 14.5313 26.8375C14.3135 26.8375 14.1046 26.7509 13.9505 26.5969C13.7965 26.4429 13.71 26.234 13.71 26.0161C13.71 25.7983 13.7965 25.5894 13.9505 25.4353L23.7943 15.5916Z"
        fill="white"
      />
      <path
        d="M23.7943 22.1541C23.8705 22.0777 23.961 22.0171 24.0607 21.9757C24.1603 21.9344 24.2672 21.9131 24.3751 21.9131C24.483 21.9131 24.5898 21.9344 24.6895 21.9757C24.7891 22.0171 24.8796 22.0777 24.9558 22.1541L34.7996 31.9978C34.9536 32.1519 35.0402 32.3608 35.0402 32.5786C35.0402 32.7965 34.9536 33.0054 34.7996 33.1594C34.6456 33.3134 34.4366 33.4 34.2188 33.4C34.001 33.4 33.7921 33.3134 33.638 33.1594L24.3751 23.8948L15.1121 33.1594C14.9581 33.3134 14.7491 33.4 14.5313 33.4C14.3135 33.4 14.1046 33.3134 13.9505 33.1594C13.7965 33.0054 13.71 32.7965 13.71 32.5786C13.71 32.3608 13.7965 32.1519 13.9505 31.9978L23.7943 22.1541Z"
        fill="white"
      />
    </svg>
  );
};
export const FooterLogo = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 192 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="96"
        cy="96"
        r="90"
        fill="#105BAB"
        stroke="white"
        strokeWidth="10"
      />
      <circle
        cx="96"
        cy="96"
        r="80"
        fill="#FFDE3F"
        stroke="#105BAB"
        strokeWidth="10"
      />
      <path
        d="M47.8889 121.321C30.1094 58.2841 81.7297 28.5716 108.5 21.5002C79.5031 17.1068 58.8336 30.0976 49.0062 37.5666C13.8584 64.2789 17.959 104.533 24.4027 121.321C43.031 168.289 88.29 174.015 108.5 170.5C72.941 162.418 52.4348 134.116 47.8889 121.321Z"
        fill="#DB9E28"
      />
      <path
        d="M149.626 119.812C147.218 137.726 130.048 143.36 121.178 144.013C114.154 147.026 117.82 150.636 120.531 152.065C148.67 149.632 156.309 130.691 156.611 121.525C155.073 116.558 150.922 118.363 149.626 119.812Z"
        fill="#FFF56C"
      />
      <path
        d="M69.5729 108.423L72.9105 107.422L69.5729 108.423L71.4145 114.562C72.4401 117.981 76.3998 119.532 79.4749 117.72L131.615 86.9948C134.165 85.4918 135.069 82.2398 133.659 79.6368L129.122 71.2605L126.044 72.9275L129.122 71.2605C127.58 68.415 123.927 67.5087 121.235 69.3038L71.7901 102.267C69.7778 103.608 68.878 106.107 69.5729 108.423Z"
        stroke="#105BAB"
        strokeWidth="7"
      />
      <path
        d="M74.3746 73.6518L76.8015 76.1522L74.3746 73.6518L69.7757 78.1156C67.2146 80.6015 67.6629 84.8306 70.6884 86.7242L121.988 118.833C124.497 120.404 127.8 119.707 129.46 117.256L134.802 109.368L131.904 107.406L134.802 109.368C136.617 106.689 135.737 103.029 132.903 101.467L80.8602 72.7817C78.7421 71.6142 76.11 71.9673 74.3746 73.6518Z"
        stroke="#105BAB"
        strokeWidth="7"
      />
      <path
        d="M81.5 100L108.5 82L120.5 89.5L92.5 106L81.5 100Z"
        fill="#FFDE3F"
      />
    </svg>
  );
};
export const Redicon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.6186 20.7911L16.7095 12.8806L24.6157 4.97145L20.5519 0.90332L12.6414 8.81245L4.73224 0.90332L0.666992 4.97145L8.57324 12.8806L0.666992 20.7897L4.73512 24.8549L12.6414 16.9458L20.5476 24.8549L24.6186 20.7911Z"
        fill="white"
      />
    </svg>
  );
};
export const TwitterIcon2 = () => {
  return (
    <svg
      className="max-w-[35px] sm:max-w-none"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="44" height="44" rx="22" fill="#1D9BF0" />
      <path
        d="M30.2872 17.7634C30.2999 17.9471 30.2999 18.1309 30.2999 18.3164C30.2999 23.9666 25.9986 30.483 18.1334 30.483V30.4795C15.8098 30.4828 13.5344 29.8174 11.5789 28.5626C11.9167 28.6032 12.2562 28.6235 12.5966 28.6243C14.5224 28.6258 16.3928 27.9799 17.9072 26.7903C17.0149 26.7734 16.1502 26.4782 15.434 25.9458C14.7178 25.4134 14.1858 24.6704 13.9125 23.8209C14.5531 23.9444 15.2136 23.9192 15.8429 23.7473C13.8481 23.3442 12.4129 21.5915 12.4129 19.5559V19.5017C13.0076 19.8329 13.6731 20.0165 14.3535 20.0369C12.4747 18.7812 11.8955 16.2816 13.03 14.3273C14.1036 15.6484 15.443 16.7288 16.9613 17.4984C18.4796 18.2681 20.1428 18.7097 21.8429 18.7947C21.6734 18.065 21.6984 17.3037 21.9155 16.5867C22.1326 15.8698 22.5341 15.2224 23.0799 14.7093C24.8021 13.0903 27.5108 13.1734 29.1298 14.8947C30.0873 14.7056 31.0056 14.3546 31.8451 13.8566C31.5259 14.8467 30.8579 15.6871 29.9654 16.2215C30.8131 16.1216 31.6409 15.8947 32.421 15.5484C31.8471 16.4078 31.1245 17.1578 30.2872 17.7634Z"
        fill="white"
      />
    </svg>
  );
};
